<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>الصرف الصحي</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row><h2 class="mt-10">معالجه الصرف الصحي</h2></v-row>
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(item, i) in ArrayOFAnElsherka"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-header
                  expand-icon="mdi-menu-down"
                  class="fade-in"
                >
                  {{ item.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h4 class="importantSite" v-if="item.text">
                    {{ item.text }}
                  </h4>
                  <h4 v-if="item.text2">
                    {{ item.text2 }}
                  </h4>
                  <ul v-if="item.subtext">
                    <li v-for="i in item.subtext" :key="i">
                      <h3 v-animate-onscroll="{ down: 'text-shadow-pop-top' }">
                        {{ i.subTitle }}
                      </h3>
                      <h4>{{ i.subtext }}</h4>
                      <br />
                      <h4>{{ i.subtext2 }}</h4>
                    </li>
                  </ul>
                  <h4 class="brown--text" v-if="item.text3">
                    {{ item.text3 }}
                  </h4>
                  <h4 v-if="item.text4">{{ item.text4 }}</h4>
                  <h4 v-if="item.text5">{{ item.text5 }}</h4>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  components: { Link },
  data() {
    return {
      panel: [0, 1],
      ArrayOFAnElsherka: [
        {
          title: "نبذه عن معالجه مياه الصرف الصحي ",
          text: "عملية تنقية مياه الصرف من الشوائب والمواد العالقة والملوثات والمواد العضوية لتصبح صالحة لإعادة الاستخدام (غير الآدمي) أو لتكون صالحة للتخلص منها في المجاري المائية دون أن تسبب تلوثا لها. تشتمل عملية معالجة الصرف على عدة مراحل فيزيائية وكيماوية وبيولوجية.",
          text2:
            "إذا جرى التخلص من مياه المجاري مثلاً بدون معالجة بإلقائها في البحر أو النهر، إلخ فسيحدث الآتي:تنتشر الميكروبات المسببة للأمراض التي تنتقل للإنسان عن طريق الاستحمام أو الشرب.",
          text4:
            "تقوم الميكروبات بتحليل المواد العضوية مستنفدة الأوكسجين الذائب في المياه لزيادة الطلب على الأوكسجين الحيوي ويؤدي ذلك إلى موت الأحياء المائية كالسمك والقشريات (ظاهرة الإغناء البيولوجي).",
          text5:
            "تنشيط الميكروبات اللاهوائية نتيجة استنفاد الأكسجين الذائب وتقوم بتخمير المواد العضوية مسببة روائح كريهة وعفونة للمياه.",
        },
        {
          title: "مصادر مياه الصرف",
          text: "تتعدد مصادر الصرف الصحي، فهناك الصرف المنزلي، والصرف الصناعي، وصرف مياه الأمطار، وماء الرشح (الخاص بتخفيض منسوب المياه الجوفية) إلخ.",
          text2:
            "غالبا ما يتكون الصرف أساساً من المواد العضوية السائلة من الحمامات، والمطابخ، والأحواض والتي يتخلص منها عن طريق أنابيب الصرف.",
          text4:
            "كما أنه في مناطق كثيرة تضم مياه الصرف أيضا المخلفات السائلة من المصانع والمستشفيات والمطاعم وتؤثر هذه المخلفات تأثيراً سلبياً على أعمال المعالجة.",
          subtext: [
            {
              id: "1",
              subTitle: "التصفية",
              subtext:
                "تتم في المصافي وهي شبكات حديدية لحجز المواد العالقة كبيرة الحجم من الورق أو قطع القماش أو الخشب أو قطع الزجاج الصفيح ويتخلص منها بالردم أو التجفيف أو الحرق.وتمر مياه الصرف على مصافي قبل أن تعالج لإزالة كل المواد الصلبة والعائمة والتي دخلت إلى مياه الصرف، مثل القطع الخشبية، الفوط، العلب المعدنية، الخ.. تصفى المياه من هذه الشوائب عن طريق مصافي آلية أو يدوية. تستخدم مصافي مزودة بقضبان بينها مسافات صغيرة مما يمنع مرور أي مواد صلبة كبيرة قد تتلف أو تتسبب في عطل أجهزة معالجة المياه بعد ذلك.",
            },
            {
              id: "2",
              subTitle: "إزالة الرمال والصخور",
              subtext:
                "عملية ازالة الرمال والصخور من مراحل المعالجة الاولية وهي في الواقع عملية الترسيب حيث تمر مياه المخلفات في أحواض ترسيب أولية بسرعة بطيئة نسبياً 30 سم/دقيقة؛ وذلك لترسيب المواد العالقة مثل الأتربة والرمال والقطع المعدنية فيتجمع في قعر الحوض ما يعرف بالحمأة الأولية Primary sludge وقد تضاف مواد كيميائية للمساهمة في عملية الترسيب مثل الشبة أو أملاح الحديد، وهي مكلفة نوعاً ما. ويطفو الزبد على السطح الذي يكشط من آن لآخر، وهو عبارة عن مواد دهنية. ",
              subtext2:
                "كما أن المعالجة تضم مرحلة ما قبل المعالجة تنقية وتنظيف المياه من الصخور والرمال عن طريق التحكم في سرعة مياه الصرف حتى تصل لسرعة تسمح بترسب الصخور الصغيرة والرمال في القاع مع إبقاء أغلب المواد العضوية العالقة في مجرى المياه. من المهم إزالة الرمال والزلط والصخور الصغيرة مبكرا لتجنب الضرر بمعدات المحطة من مضخات وخلافه. في بعض الأحيان يكون هناك ما يسمى مغسله الرمل والتي يتلوها ناقلة تنقل الرمل إلى مكان يمكن إعادة استخدامه فيه، ولكن غالبا ما يتخلص من الرمال والصخور بإلقاءها في مدفن قمامة.",
            },
            {
              id: "3",
              subTitle: "الترسيب",
              subtext:
                "خزان ترسيب أولي فارغ.في مرحلة الترسيب الأولى، يضخ الصرف إلى خزانات ضخمة تسمى خزانات الترسيب الأولية. تكون هذه الخزانات كبيرة بما يكفي بحيث تترسب الأوحال والمواد القذرة في القاع وتصعد المواد العائمة والشحوم والزيوت إلى السطح ليتم كشطها. الهدف من عملية الترسيب الأولية هي إنتاج سائل متجانس بشكل عام يمكن معالجته بعد ذلك بيولوجيا وكذلك أيضا استخلاص القاذورات بحيث يمكن التخلص منها بعد ذلك أو إعادة استخدامها. غالباً ما تضم خزانات الترسيب الأولية مكشطة ميكانيكية تقوم بطرد المواد القذرة بشكل مستمر إلى فتحة أسفل الخزان حيث تضخ لتعالج في مراحل أخرى.",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style >
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
</style>